<template>
  <div class="config">
    <div class="operation">
      <div class="left">
        <el-button type="primary" @click="handleOpen">添加盒子</el-button>
      </div>
    </div>
    <el-table ref="singleTable" v-loading="isFetching" :data="tableData" @sort-change="sortChange" row-key="index">
      <el-table-column
        v-for="(item, index) in columns"
        :min-width="item.width"
        :key="index"
        :label="item.label"
        :prop="item.key"
        :sortable="item.sortable"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="item.key === 'status'">
            <status :data="scope.row.status" />
          </template>
          <template v-else-if="item.key === 'boxType'">
            <span>{{ formatLabel(productTypeList, scope.row.boxType) }}</span>
          </template>
          <div v-else>{{ isEmpty(scope.row[item.key]) ? '--' : scope.row[item.key] }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="100">
        <template slot-scope="scope">
          <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
          <el-button @click="toCameraManage(scope.row)" type="text" size="small">摄像头管理</el-button>
          <el-button @click="toDeviceInfo(scope.row)" type="text" size="small">设备信息查询</el-button>
        </template>
      </el-table-column>
    </el-table>
    <dialog-add-box :dialogVisible="dialogVisible" @confirm="handleConfirm" @close="handleClose" />
    <div class="footer">
      <el-button class="cancel" @click="() => this.$router.back()">取消</el-button>
      <el-button type="primary" @click="handleNext">确定</el-button>
    </div>
  </div>
</template>

<script>
import { columns1 } from '../common'
import { storeHttp, formatLabel, generateTableData } from 'src/utils/common'
import { getProductList } from 'src/api/product'
import { getNewestToken } from 'src/api/legacy'
import { isEmpty } from 'src/utils/method'
import DialogAddBox from 'src/components/DialogAddBox'
import { solution } from 'src/api/product'
import { mapState } from 'vuex'
import Status from 'src/components/Status'
import { productTypeList } from 'src/utils/dict'

export default {
  components: {
    DialogAddBox,
    Status,
  },
  data() {
    const { boxId } = this.$route.query
    return {
      columns: columns1,
      isFetching: false,
      dataSource: [],
      tableData: [],

      prop: '',
      order: '',

      dialogVisible: false,
      boxId,
      productTypeList,
    }
  },
  computed: {
    ...mapState({
      step_1: (state) => state.solution.step_1,
    }),
  },
  watch: {
    step_1(data) {
      if (data.productId) {
        this.fetchList()
      }
    },
  },
  mounted() {},
  methods: {
    isEmpty,
    formatLabel,
    init() {
      this.fetchList()
    },
    async handleConfirm(item) {
      if (item) {
        this.fetchList()
        this.dialogVisible = false
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCancel() {
      this.dialogVisible = false
    },
    handleDelete(row) {
      this.$msgbox({
        message: `盒子（MAC/端口号：${row.macId}/${row.boxPort}）已绑定${row.cameraCount}个摄像头，确认删除？`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: true,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            const { status } = await solution.removeBox({
              boxId: row.boxId,
              productId: row.productId,
            })
            if (status === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.fetchList()
            }
            instance.confirmButtonLoading = false
          }
          done()
        },
      })
    },
    updateIndexForTable(data = []) {
      let i = 0
      for (const item of data) {
        item.index = i++
      }
      return data
    },
    handleTableChange(val) {
      this.currentRow = val
    },
    handleOpen() {
      this.dialogVisible = true
    },
    handleNext() {
      if (this.tableData.length === 0) {
        this.$message.warning('请至少添加一条数据')
        return
      }
      this.$store.dispatch('solution/updateStep_2', [...this.tableData])

      this.$emit('change', 2)
    },

    async fetchList() {
      this.isFetching = true

      try {
        const { status, data } = await solution.getBoxByProductId({
          productId: this.step_1.productId,
        })
        if (status === 0) {
          this.tableData = data || []
        }
        // this.total = res.data?.length || 0
        // this.formatTableData()
      } finally {
        this.isFetching = false
      }
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData()
    },
    formatTableData() {
      // this.tableData = generateTableData(this.dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
      this.tableData = this.dataSource
    },
    toCameraManage(row) {
      let name = ''
      const type = parseInt(this.$route.query.type)
      if (type === 0) {
        name = 'retail-traffic-cameraManage'
      } else if (type === 4) {
        name = 'security-cameraManage'
      }

      const routeData = this.$router.resolve({
        query: {
          shopId: row.shopId,
          productId: row.productId,
          boxId: row.boxId,
          boxType: row.boxType,
        },
        name,
      })
      window.open(routeData.href, '_blank')
    },
    async toDeviceInfo(row) {
      let href = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0]?.username
      // const accessToken = localStorage.getItem('accessToken')
      const { data: accessToken } = await getNewestToken({})
      window.open(
        href +
          '?accessToken=' +
          accessToken +
          '&boxId=' +
          row.boxId +
          '&username=' +
          username +
          '&type=0' +
          '&boxType=0'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.operation {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.footer {
  text-align: center;
  margin-top: 60px;
  .cancel {
    margin-right: 20px;
  }
}
</style>